import { useEffect, useRef, useState } from 'react'

import Image from 'next/image'

import {
  type PricesState,
  type TypeUserState,
  sendBeaconData,
  setSponsoredProductsLocalStorage,
  usePrices,
  useUser,
  useWishlist
} from '@k_frontend/core'
import { IconAddToCart, IconGift } from '@k_frontend/icons'
import ProductFooter from 'components/Product/Footer'
import Prices from 'components/Product/Prices'
import SkeletonPrices from 'components/Product/Prices/SkeletonPrices'
import UnavailablePrices from 'components/Product/Prices/UnavailablePrices'
import RatingStars from 'components/RatingStarsV2'
import Stamp from 'components/Stamp'
import Tags from 'components/Tags'
import { useTimestamp } from 'hooks/useTimestamp'
import { OFFER_TYPE } from 'pages/ofertas/ofertas.types'
import { STATIC_KABUM, STATIC_KABUM_IMAGES_K5 } from 'settings/kernel'
import theme from 'theme'
import { getTextFromHtml } from 'utils/html-parser'
import { onNextImageError } from 'utils/onNextImageError'
import { pricesErrorNotify } from 'utils/pricesErrorNotify'
import { shallow } from 'zustand/shallow'

import { FreeShippingTag } from 'components/Product/FreeShippingTag'
import useIsVisible from 'hooks/useIsVisible'
import { formatSendBeaconData } from 'utils/formatSendBeaconData'
import ButtonAddRemoveToCart from '../../../ButtonAddRemoveToCart'
import { ProductProps } from '../../Product.types'
import { ProductGridMobileLoading } from './ProductGridMobile.loading'
import * as S from './ProductGridMobile.styles'

const useUserSelector = (state: TypeUserState) => ({
  isPrime: state.isPrime,
  clientId: state.clientId,
  session: state.session
})

const selectorUsePrices = (state: PricesState, productId: number) => ({
  prices: state.selectorPricesDetails({ state, productId })?.prices,
  loading: state.selectorPricesDetails({ state, productId })?.loading,
  offer: state.selectorPricesDetails({ state, productId })?.offer,
  error: state.pricesDetail[productId]?.error
})

export default function ProductGridMobile({
  product,
  productPathLink = 'produto',
  fnOnProductClick,
  isFullPrime,
  smartHintFeature,
  update,
  handleClickWishList,
  offerType
}: ProductProps) {
  const productGridMobileRef = useRef()
  const productId = product?.code
  const isInWishlist = useWishlist((state) => state.isInWishlist)
  const [imageSrc, setImageSrc] = useState(product.thumbnail || product.image)
  const [productImageLoading, setProductImageLoading] = useState(true)
  const [onScreenProductGridMobile, setOnScreenProductGridMobile] =
    useState(false)
  const [wasPrintedProductGridMobile, setWasPrintedProductGridMobile] =
    useState(false)

  const mainProductGridMobileObserver = useIsVisible(
    setOnScreenProductGridMobile,
    {
      threshold: 0.75
    }
  )

  const {
    isPrime: isUserPrime,
    clientId,
    session
  } = useUser(useUserSelector, shallow)

  const { prices, offer, error } = usePrices(
    (state) => selectorUsePrices(state, productId),
    shallow
  )

  const { timestamp } = useTimestamp()

  const isExpired =
    product?.offer?.hashCode && timestamp >= product.offer?.endsAt

  const outOfStock =
    product?.offer?.hashCode && (product.quantity === 0 || update === 0)

  const stockQuantity = offerType
    ? update
    : product?.offer?.quantityAvailable ||
      offer?.quantityAvailable ||
      product?.quantity

  const isActiveOffer =
    timestamp >= product?.offer?.startsAt || timestamp >= offer?.startsAt

  const productStamps = product.stamps
  const isBlackLegendary = productStamps?.type === 'blackLegendary'

  const { flags } = product
  const {
    isFlash,
    isPrime,
    isOffer,
    isOpenbox,
    isPreOrder,
    isSponsored,
    hasGift
  } = flags

  const showPrimeTag = !isUserPrime && isPrime && isOffer

  const isProductInWishlistAndHasClientId =
    isInWishlist(Number(product.code)) && !!clientId
  const wishlistButtonLabel = `${isProductInWishlistAndHasClientId ? 'Remover da' : 'Adicionar na'} lista de desejos`

  const isAvailable = product?.available

  const isFreeShipping =
    flags.isFreeShipping || (flags.isFreeShippingPrime && isUserPrime)

  if (productGridMobileRef.current && !wasPrintedProductGridMobile) {
    mainProductGridMobileObserver?.observe(productGridMobileRef.current)
  }

  function getIsFullPrime(): boolean {
    return isFullPrime || (isPrime && isUserPrime)
  }

  function getOfferDiscount() {
    const primeDiscount =
      prices?.primeDiscountPercentage || product?.prime?.discountPercentage
    const offerDiscount =
      product?.offer?.discountPercentage || offer?.discountPercentage

    return getIsFullPrime() ? primeDiscount : offerDiscount
  }

  function renderButtonAddToCart() {
    if (offerType === OFFER_TYPE.CLOSED) {
      return (
        <a
          href={`/${productPathLink}/${product.code}/${product?.friendlyName}`}
        >
          <IconAddToCart color={theme.colors.black(550)} />
        </a>
      )
    }

    if (isAvailable && (offerType !== OFFER_TYPE.SCHEDULED || isActiveOffer)) {
      return (
        <ButtonAddRemoveToCart
          minimalist
          productData={product}
          disabled={error}
        />
      )
    }

    return null
  }

  function getProductHeader() {
    return (
      <>
        <S.ContainerHeader className='cardHeader'>
          {productStamps && (
            <div className='flex'>
              <Stamp stamps={productStamps} />
            </div>
          )}
          {!productStamps && (
            <S.WrapperRatingStars>
              {product?.ratingCount > 0 &&
                !isSponsored &&
                (!isExpired || offerType !== OFFER_TYPE.CLOSED) && (
                  <>
                    <RatingStars
                      maxRating={5}
                      rating={product.rating}
                      starSize={10}
                    />
                    <S.RatingLabel>({product.ratingCount})</S.RatingLabel>
                  </>
                )}
              {isSponsored && <S.SponsoredText>Patrocinado</S.SponsoredText>}
            </S.WrapperRatingStars>
          )}

          <S.ActionsHeaderContainer>
            <S.WishlistButton
              aria-label={wishlistButtonLabel}
              aria-pressed={isProductInWishlistAndHasClientId}
              className='wishlistCard'
              onClick={() => handleClickWishList()}
            >
              {isProductInWishlistAndHasClientId ? (
                <Image
                  src={`${STATIC_KABUM}/conteudo/icons/iconHeartFilled.svg`}
                  width={20}
                  height={18}
                  alt=''
                />
              ) : (
                <Image
                  src={`${STATIC_KABUM}/conteudo/icons/iconHeart.svg`}
                  width={20}
                  height={18}
                  alt=''
                />
              )}
            </S.WishlistButton>

            {renderButtonAddToCart()}
          </S.ActionsHeaderContainer>
        </S.ContainerHeader>
      </>
    )
  }

  function getProductTags() {
    const isPrimeLogo = showPrimeTag

    if (
      isAvailable &&
      !error &&
      (isPrimeLogo || isOpenbox || isPreOrder || hasGift)
    ) {
      return (
        <S.TagsContainer>
          <Tags
            className='tagsCard flex-col !gap-4 items-start'
            color='primary'
            code={product.code}
            small={true}
            isPrimeLogo={isPrimeLogo}
            isPreOrder={isPreOrder}
            hasGift={hasGift}
            limit={3}
          />
        </S.TagsContainer>
      )
    }

    return null
  }

  function getProductPrices() {
    if (!timestamp) {
      return <SkeletonPrices />
    }

    if (!isAvailable || error) {
      return <UnavailablePrices color={theme.colors.secondary(500)} />
    }

    return (
      <Prices
        productId={productId}
        isPrime={getIsFullPrime()}
        isAvailable={isAvailable}
        product={product}
        isClosedOffer={offerType === OFFER_TYPE.CLOSED}
        outOfStock={outOfStock && offerType !== OFFER_TYPE.CLOSED}
        isScheduledOffer={
          product?.offer?.hashCode && timestamp <= product?.offer?.startsAt
        }
        offerDiscount={getOfferDiscount()}
        isActiveOffer={isActiveOffer}
        isOffer={isOffer}
        stockQuantity={stockQuantity}
        isBlackLegendary={isBlackLegendary}
      />
    )
  }

  useEffect(() => {
    pricesErrorNotify(error)
  }, [error])

  useEffect(() => {
    if (
      product?.newtail &&
      onScreenProductGridMobile &&
      !wasPrintedProductGridMobile
    ) {
      sendBeaconData(
        product?.newtail.viewUrl,
        formatSendBeaconData(clientId, session)
      )
      setWasPrintedProductGridMobile(true)
      mainProductGridMobileObserver.disconnect()
    }
  }, [onScreenProductGridMobile])

  return (
    <div className={S.cardWrapper({ type: productStamps?.type || 'normal' })}>
      <S.Product
        ref={productGridMobileRef}
        className='productCard'
        onClick={() => {
          isSponsored && setSponsoredProductsLocalStorage(product.code)
          fnOnProductClick(product)
        }}
      >
        <S.ProductHeader>{getProductHeader()}</S.ProductHeader>
        <S.ProductBody
          href={`/${productPathLink}/${product.code}/${product?.friendlyName}`}
          className='productLink'
          data-smarthintproductid={product.code.toString()}
          data-smarthintfeature={smartHintFeature}
          hasNoImage={productImageLoading}
        >
          <S.ProductInfo>
            <S.ProductImgTags>
              {productImageLoading && <ProductGridMobileLoading />}
              <Image
                src={imageSrc}
                title={product.name}
                alt={
                  getTextFromHtml(product.description)?.substring(0, 150) ||
                  product.name
                }
                onError={(e) =>
                  setImageSrc(
                    onNextImageError(e, STATIC_KABUM_IMAGES_K5, 'gridCard')
                  )
                }
                onLoad={() => setProductImageLoading(false)}
                width={92}
                height={92}
                priority
              />
              <div className='flex flex-col gap-4'>
                <S.ProductTags>
                  {isFlash && <S.Flash className='flashTagCard' />}
                </S.ProductTags>
                {getProductTags()}
              </div>
            </S.ProductImgTags>
            <S.BodyInfo>
              <S.ContentProductName>
                <div className='mb-4 h-16'>
                  {isFreeShipping && <FreeShippingTag />}
                </div>
                <h3>
                  <S.ProductName lines={3} className='nameCard'>
                    {product.name}
                  </S.ProductName>
                </h3>
              </S.ContentProductName>
              {getProductPrices()}
            </S.BodyInfo>
          </S.ProductInfo>
        </S.ProductBody>
        <S.ProductFooter>
          <ProductFooter
            product={product}
            isFullPrime={getIsFullPrime()}
            outOfStock={outOfStock}
            isExpired={isExpired}
            isBlackLegendary={isBlackLegendary}
          />
        </S.ProductFooter>
      </S.Product>
    </div>
  )
}
